/**
 * @type {String[]}
 * @see https://worldpopulationreview.com/country-rankings/what-countries-have-states
 */
export const COUNTRIES_WITH_REGION = [
  'CH',
  'ES',
  'ET',
  'FR',
  'ID',
  'IT',
  'JP',
  'RU',
  'SA',
  'UK',
  'VN',
];

/**
 * @type {String[]}
 * @see https://worldpopulationreview.com/country-rankings/what-countries-have-states
 */
export const COUNTRIES_WITH_STATE = [
  'AU',
  'BR',
  'CA',
  'DE',
  'IN',
  'MX',
  'US',
];

/**
 * @type {String[]}
 */
export const COUNTRIES_WITH_DELIVERY_OPTIONS = [
  'BE',
  'NL',
];

/**
 * Countries with the address finder functionality.
 *
 * @type {String[]}
 */
export const COUNTRIES_WITH_ADDRESS_FINDER = [
  'NL',
];
