import { maxLength, required } from 'vuelidate/lib/validators';
import { AbstractField } from '@/data/forms/fields/abstractField';

export class PersonField extends AbstractField {
  getField() {
    return {
      name: 'person',
      inputAttributes: {
        autocomplete: 'name',
      },
      validations: {
        required,
        maxLength: maxLength(50),
      },
    };
  }
}
