import { AbstractField } from '@/data/forms/fields/abstractField';
import { maxLength } from 'vuelidate/lib/validators';

export class NumberSuffixField extends AbstractField {
  getField() {
    return {
      name: 'number_suffix',
      label: 'form.address_number_suffix',
      inputAttributes: {
        autocomplete: 'off',
      },
      validations: {
        maxLength: maxLength(6),
      },
    };
  }
}
