<template>
  <Card
    title="social_shipping.address_title"
    :loading="true">
    <SkeletonLoader>
      <FormField
        row
        skeleton />
      <div class="grid grid--auto-cols">
        <FormField skeleton />
        <FormField skeleton />
        <FormField skeleton />
      </div>
      <FormField
        row
        skeleton />
      <FormField
        row
        skeleton />
    </SkeletonLoader>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import FormField from '@/components/forms/FormField';
import SkeletonLoader from '@/components/SkeletonLoader';

export default {
  name: 'SocialShippingAddressFormSkeleton',
  components: {
    Card,
    SkeletonLoader,
    FormField,
  },
};
</script>
