import { CompanyField } from '@/data/forms/fields/companyField';
import { EmailField } from '@/data/forms/fields/emailField';
import { HiddenField } from '@/data/forms/fields/hiddenField';
import { PersonField } from '@/data/forms/fields/personField';
import { PhoneField } from '@/data/forms/fields/phoneField';
import { required } from 'vuelidate/lib/validators';

export const getSocialShippingContactForm = (cc) => {
  const isRussia = cc === 'RU';

  return [
    ...[
      isRussia
        ? new CompanyField({
          model: 'socialShipping/recipient.company',
          validations: { required },
        })
        : new CompanyField('socialShipping/recipient.company'),
    ],
    new PersonField('socialShipping/recipient.person'),
    new EmailField('socialShipping/recipient.email'),
    new PhoneField('socialShipping/recipient.phone'),
    // This field is needed for the phone validation
    new HiddenField({
      model: 'socialShipping/recipient.cc',
      name: 'cc',
    }),
  ];
};
