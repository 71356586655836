import { COUNTRIES_WITH_ADDRESS_FINDER } from '@/data/forms/countryData';

/**
 * Returns whether given country is allowed to use the address finder.
 *
 * @param {String} cc
 *
 * @returns {Boolean}
 */
export function hasAddressFinder(cc) {
  return COUNTRIES_WITH_ADDRESS_FINDER.includes(cc.toUpperCase());
}
