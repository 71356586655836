import { alpha, maxLength } from 'vuelidate/lib/validators';
import { AbstractField } from '@/data/forms/fields/abstractField';

export class StateField extends AbstractField {
  getField() {
    return {
      name: 'state',
      label: 'form.state',
      inputAttributes: {
        autocomplete: 'address-level1',
      },
      validations: {
        maxLength: maxLength(2),
        alpha,
      },
    };
  }
}
