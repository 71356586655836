import { AbstractField } from '@/data/forms/fields/abstractField';
import { maxLength } from 'vuelidate/lib/validators';

export class CompanyField extends AbstractField {
  getField() {
    return {
      name: 'company',
      inputAttributes: {
        autocomplete: 'company',
      },
      validations: {
        maxLength: maxLength(50),
      },
    };
  }
}
