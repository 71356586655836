/* eslint-disable max-lines-per-function */
import { COUNTRIES_WITH_REGION, COUNTRIES_WITH_STATE } from '@/data/forms/countryData';
import { ADDRESS_FINDER_GROUP } from '@/data/forms/addressFinder';
import AddressCheckError from '@/components/forms/AddressCheckError';
import { CityField } from '@/data/forms/fields/cityField';
import { CountryField } from '@/data/forms/fields/countryField';
import { NumberField } from '@/data/forms/fields/numberField';
import { NumberSuffixField } from '@/data/forms/fields/numberSuffixField';
import { PostalCodeField } from '@/data/forms/fields/postalCodeField';
import { RegionField } from '@/data/forms/fields/regionField';
import { StateField } from '@/data/forms/fields/stateField';
import { StreetAdditionalInfoField } from '@/data/forms/fields/streetAdditionalInfoField';
import { StreetField } from '@/data/forms/fields/streetField';
import { hasAddressFinder } from '@/data/forms/hasAddressFinder';
import { isDemo } from '@/services/functions/isDemo';

/**
 * Get the correct address form, depending on country.
 *
 * @param {string} cc
 * @returns {Object[]}
 */
export function getSocialShippingAddressForm(cc) {
  const hasRegion = COUNTRIES_WITH_REGION.includes(cc);
  const hasState = COUNTRIES_WITH_STATE.includes(cc);
  const hasAddressCheck = hasAddressFinder(cc);

  return [
    new CountryField({
      model: 'socialShipping/recipient.cc',
      inputAttributes: {
        disabled: true,
      },
    }),
    ...hasState ? [new StateField('socialShipping/recipient.state')] : [],
    ...hasAddressCheck
      ? [
        {
          name: ADDRESS_FINDER_GROUP,
          children: [
            new PostalCodeField({
              model: 'socialShipping/recipient.postal_code',
              attributes: {
                class: 'col-5',
              },
              inputAttributes: {
                autocomplete: 'postal-code',
              },
            }),
            new NumberField({
              model: 'socialShipping/recipient.number',
              label: 'form.address_number_short',
            }),
            new NumberSuffixField({
              model: 'socialShipping/recipient.number_suffix',
            }),
          ],
        },
        ...isDemo() ? [] : [{ component: AddressCheckError }],
      ]
      : [],
    new StreetField('socialShipping/recipient.street'),
    new StreetAdditionalInfoField('socialShipping/recipient.street_additional_info'),
    ...hasAddressCheck
      ? []
      : [
        new PostalCodeField({
          model: 'socialShipping/recipient.postal_code',
          inputAttributes: {
            autocomplete: 'postal-code',
          },
        }),
      ],
    new CityField('socialShipping/recipient.city'),
    ...hasRegion ? [new RegionField('socialShipping/recipient.region')] : [],
  ];
}
