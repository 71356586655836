import { AbstractField } from '@/data/forms/fields/abstractField';
import { maxLength } from 'vuelidate/lib/validators';

export class StreetAdditionalInfoField extends AbstractField {
  getField() {
    return {
      name: 'street_additional_info',
      label: 'form.street_additional_info',
      validations: {
        maxLength: maxLength(50),
      },
    };
  }
}
