<template>
  <Card
    title="social_shipping.delivery_options_title"
    :loading="true">
    <SkeletonLoader>
      <div v-skeleton="[32, 36, 28]" />
    </SkeletonLoader>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import SkeletonLoader from '@/components/SkeletonLoader';

export default {
  name: 'SocialShippingDeliveryOptionsSkeleton',
  components: {
    Card,
    SkeletonLoader,
  },
};
</script>
