import { maxLength, minValue, numeric, required } from 'vuelidate/lib/validators';
import { AbstractField } from '@/data/forms/fields/abstractField';

export class NumberField extends AbstractField {
  getField() {
    return {
      name: 'number',
      label: 'form.address_number',
      inputAttributes: {
        autocomplete: 'off',
      },
      validations: {
        maxLength: maxLength(8),
        minValue: minValue(1),
        numeric,
        required,
      },
    };
  }
}
