<template>
  <SmoothReflow>
    <Alert
      v-if="show"
      v-t="'form.error_find_address'"
      variant="warning" />
  </SmoothReflow>
</template>

<script>
import Alert from '@/views/Alert';
import { socialShippingFormBus } from '@/views/SocialShipping/socialShippingFormBus';

export default {
  name: 'AddressCheckError',
  components: { Alert },

  data() {
    return {
      show: false,

      /**
       * Show or hide self based on whether a valid address was found by findAddress.
       *
       * @param {Object|null} value
       */
      onFindAddress: (value) => {
        this.show = !value;
      },
    };
  },

  mounted() {
    socialShippingFormBus.$on('findAddress:end', this.onFindAddress);
  },

  beforeDestroy() {
    socialShippingFormBus.$off('findAddress:end', this.onFindAddress);
  },
};
</script>
