<template>
  <Card
    title="social_shipping.finalize_title"
    class="text-center"
    :loading="true">
    <SkeletonLoader>
      <p v-skeleton="$t('social_shipping.finalize_description')" />
      <CButton v-skeleton="$t('form.submit')" />
    </SkeletonLoader>
  </Card>
</template>

<script>
import CButton from '@/components/CButton';
import Card from '@/components/Card';
import SkeletonLoader from '@/components/SkeletonLoader';

export default {
  name: 'SocialShippingFinalizeSkeleton',
  components: {
    Card,
    CButton,
    SkeletonLoader,
  },
};
</script>
