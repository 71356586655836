<template>
  <Card
    title="social_shipping.introduction_title"
    class="text-center"
    :loading="true">
    <SkeletonLoader>
      <p v-skeleton="$t('social_shipping.introduction_description')" />
    </SkeletonLoader>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import SkeletonLoader from '@/components/SkeletonLoader';

export default {
  name: 'SocialShippingIntroductionSkeleton',
  components: {
    Card,
    SkeletonLoader,
  },
};
</script>
