import { AbstractField } from '@/data/forms/fields/abstractField';

export class HiddenField extends AbstractField {
  /**
   * @returns {{inputAttributes: {type: string}}}
   */
  getField() {
    return {
      inputAttributes: {
        type: 'hidden',
      },
    };
  }
}
