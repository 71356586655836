<template>
  <Page
    logo
    :actions="['socialShipping/fetchSocialShippingLink']"
    :error="error">
    <SmoothReflow
      :options="{
        transitionEvent: {
          selector: '.grid',
        },
      }">
      <Transition
        appear
        name="grid"
        mode="out-in">
        <div
          v-if="loadingSocialShippingLink"
          v-test="'skeleton'"
          class="grid social-shipping social-shipping--has-delivery-options">
          <SocialShippingIntroductionSkeleton class="social-shipping__introduction" />
          <SocialShippingContactFormSkeleton class="social-shipping__contact" />
          <SocialShippingAddressFormSkeleton class="social-shipping__adress" />
          <SocialShippingDeliveryOptionsSkeleton class="social-shipping__delivery-options" />
          <SocialShippingFinalizeSkeleton class="social-shipping__finalize" />
        </div>

        <SocialShippingFinished
          v-else-if="linkIsUsed"
          v-test="'finished'"
          class="grid" />

        <form
          v-else
          v-test="'ready'"
          class="grid social-shipping"
          :class="{
            'social-shipping--has-delivery-options': hasDeliveryOptions,
          }"
          @submit.prevent="onSubmit">
          <SocialShippingIntroduction class="social-shipping__introduction" />
          <SocialShippingContactForm class="social-shipping__contact" />
          <SocialShippingAddressForm class="social-shipping__adress" />
          <SocialShippingDeliveryOptions
            v-if="hasDeliveryOptions"
            class="social-shipping__delivery-options" />
          <SocialShippingFinalize
            class="social-shipping__finalize"
            :has-delivery-options="hasDeliveryOptions"
            @error="error = $event" />
        </form>
      </Transition>
    </SmoothReflow>
  </Page>
</template>

<script>
import { MYPARCEL, SENDMYPARCEL } from '@/helpers/platform/data';
import { STORE_MODULE_SOCIAL_SHIPPING, STORE_RECIPIENT } from '@/store/modules/social-shipping/constants';
import { createSocialShippingFormBus, socialShippingFormBus } from '@/views/SocialShipping/socialShippingFormBus';
import { mapGetters, mapState } from 'vuex';
import { COUNTRIES_WITH_DELIVERY_OPTIONS } from '@/data/forms/countryData';
import Page from '@/views/Page';
import SocialShippingAddressFormSkeleton from './skeletons/SocialShippingAddressFormSkeleton';
import SocialShippingContactFormSkeleton from './skeletons/SocialShippingContactFormSkeleton';
import SocialShippingDeliveryOptionsSkeleton from './skeletons/SocialShippingDeliveryOptionsSkeleton';
import SocialShippingFinalizeSkeleton from './skeletons/SocialShippingFinalizeSkeleton';
import SocialShippingIntroductionSkeleton from './skeletons/SocialShippingIntroductionSkeleton';
import config from '@/helpers/config';
import { platform } from '@/helpers/platform';

export default {
  name: 'SocialShipping',
  components: {
    SocialShippingFinished: () => import('./SocialShippingFinished'),
    Page,
    SocialShippingAddressForm: () => import('./SocialShippingAddressForm'),
    SocialShippingAddressFormSkeleton,
    SocialShippingContactForm: () => import('./SocialShippingContactForm'),
    SocialShippingContactFormSkeleton,
    SocialShippingDeliveryOptions: () => import('./SocialShippingDeliveryOptions'),
    SocialShippingDeliveryOptionsSkeleton,
    SocialShippingFinalize: () => import('./SocialShippingFinalize'),
    SocialShippingFinalizeSkeleton,
    SocialShippingIntroduction: () => import('./SocialShippingIntroduction'),
    SocialShippingIntroductionSkeleton,
  },

  data() {
    return {
      error: null,
    };
  },

  computed: {
    ...mapState('socialShipping', ['loadingSocialShippingLink', 'socialShippingLink']),
    ...mapGetters('socialShipping', ['recipient']),

    /**
     * Whether the social shipping link has already been used or not.
     *
     * @returns {boolean}
     */
    linkIsUsed() {
      return Boolean(this.socialShippingLink?.generated_shipment_id);
    },

    /**
     * Check if the social shipping link's options allow delivery options.
     *
     * @returns {boolean}
     */
    hasDeliveryOptions() {
      const isValidPlatform = [SENDMYPARCEL, MYPARCEL].includes(platform);
      const cc = this.socialShippingLink?.shipment_options.recipient.cc || config.defaultCC;

      return isValidPlatform && COUNTRIES_WITH_DELIVERY_OPTIONS.includes(cc.toUpperCase());
    },
  },

  watch: {
    /**
     * When the social shipping link gets created and is not used yet, initialize the form data.
     *
     * @param {boolean} data
     */
    socialShippingLink(data) {
      if (!data || this.linkIsUsed || socialShippingFormBus) {
        return;
      }

      const cc = this.recipient.cc.toUpperCase();
      createSocialShippingFormBus(cc);

      socialShippingFormBus.$on('update:recipient', (recipient) => {
        this.$store.commit(`${STORE_MODULE_SOCIAL_SHIPPING}/${STORE_RECIPIENT}`, recipient);
      });
    },
  },

  beforeDestroy() {
    if (socialShippingFormBus) {
      socialShippingFormBus.$off('update:recipient');
    }
  },

  methods: {
    onSubmit() {
      socialShippingFormBus.$emit('submit');
    },
  },
};
</script>
