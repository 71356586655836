import { maxLength, required } from 'vuelidate/lib/validators';
import { AbstractField } from '@/data/forms/fields/abstractField';

export class CityField extends AbstractField {
  getField() {
    return {
      name: 'city',
      label: 'form.address_city',
      inputAttributes: {
        autocomplete: 'address-level2',
      },
      validations: {
        required,
        maxLength: maxLength(35),
      },
    };
  }
}
