/* eslint-disable max-lines-per-function,jsdoc/check-types */
import { ADDRESS_FINDER_FIELDS, ADDRESS_FINDER_GROUP } from '@/data/forms/addressFinder';
import Vue from 'vue';
import { cloneDeep } from 'lodash-es/lang';
import { createValidationMixin } from '@/mixins/validation/createValidationMixin';
import { get } from 'lodash-es/object';
import { getSocialShippingAddressForm } from '@/data/forms/getSocialShippingAddressForm';
import { getSocialShippingContactForm } from '@/data/forms/getSocialShippingContactForm';
import { waitForValidation } from '@/helpers/validation/waitForValidation';

/**
 * @type {Vue|*} - The wildcard is here because phpstorm won't resolve properties otherwise...
 */
export let socialShippingFormBus = null;

/**
 * This component holds all form and validation data for the social shipping page. It's a separate component because
 * inside SocialShipping/index.vue we have to wait until the social shipping link is fetched before initializing this
 * data.
 *
 * @param {String} cc
 * @returns {Vue}
 */
export function createSocialShippingFormBus(cc) {
  socialShippingFormBus = new Vue({
    name: 'SocialShippingFormBus',
    mixins: [
      createValidationMixin(
        [
          { name: 'addressForm', children: getSocialShippingAddressForm(cc) },
          { name: 'contactForm', children: getSocialShippingContactForm(cc) },
        ],
      ),
    ],

    computed: {
      /**
       * Creates a recipient object string(!) from the formModel by flattening the contact and address form.
       *
       * Note: Object is deep-cloned, otherwise the watcher can't detect changes.
       *
       * @see https://github.com/vuejs/vue/issues/2164
       *
       * @returns {String}
       */
      recipient() {
        const recipient = {
          ...this.formModel.contactForm ?? {},
          ...this.formModel.addressForm ?? {},
        };

        return cloneDeep(recipient);
      },
    },

    watch: {
      recipient: {
        deep: true,

        /**
         * Marks recipient as invalid or not after validation to avoid letting the delivery options do a request we
         *  already know will return an error. This is done here and not in the computed property because async computed
         *  properties aren't supported right now (without external dependencies).
         *
         * @param {Object} newRecipient
         * @param {Object} oldRecipient
         * @returns {Promise}
         */
        async handler(newRecipient, oldRecipient) {
          newRecipient.isValid = await this.validateAddress();

          this.$emit('update:recipient', newRecipient, oldRecipient);
        },
      },
    },

    methods: {
      waitForValidation,

      /**
       * Touch the form and check whether it's ready to submit and all input is valid.
       *
       * @returns {Boolean}
       */
      isReady() {
        this.$v.$touch();

        return !(this.$v.$pending || this.$v.$anyError);
      },

      /**
       * Validate only the address. Emits events so other components can keep track of it.
       *
       * @returns {Promise<Boolean>}
       */
      async validateAddress() {
        this.$emit('lookup_address:start');
        await this.waitForValidation();

        let fieldsToCheck = ['postal_code', 'street', 'city'];

        // If the address finder fields are present we check its specific fields instead.
        if (this.$v.formModel.addressForm?.[ADDRESS_FINDER_GROUP]) {
          fieldsToCheck = [
            ...ADDRESS_FINDER_FIELDS.map((field) => `${ADDRESS_FINDER_GROUP}.${field}`),
            'street',
            'city',
          ];
        }

        const invalidFields = fieldsToCheck.filter((field) => {
          return get(this.$v.formModel.addressForm, `${field}.$invalid`, false);
        });

        const isValid = invalidFields.length === 0;
        this.$emit('lookup_address:end', isValid);
        return isValid;
      },
    },
  });

  return socialShippingFormBus;
}
