/**
 * Key used in forms which contains below fields.
 *
 * @type {String}
 */
export const ADDRESS_FINDER_GROUP = 'addressFinder';

/**
 * All fields the address finder uses.
 *
 * @type {String[]}
 */
export const ADDRESS_FINDER_FIELDS = [
  'number',
  'number_suffix',
  'postal_code',
];

/**
 * Fields required for the address finder to be used.
 *
 * @type {String[]}
 */
export const ADDRESS_FINDER_REQUIRED_FIELDS = [
  'number',
  'postal_code',
];
