<template>
  <Card
    title="social_shipping.contact_title"
    :loading="true">
    <SkeletonLoader>
      <FormField skeleton />
      <FormField skeleton />
      <FormField skeleton />
    </SkeletonLoader>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import FormField from '@/components/forms/FormField';
import SkeletonLoader from '@/components/SkeletonLoader';

export default {
  name: 'SocialShippingContactFormSkeleton',
  components: {
    Card,
    SkeletonLoader,
    FormField,
  },
};
</script>
