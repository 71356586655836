import { email, required } from 'vuelidate/lib/validators';
import { AbstractField } from '@/data/forms/fields/abstractField';

export class EmailField extends AbstractField {
  getField() {
    return {
      name: 'email',
      type: 'email',
      inputAttributes: {
        autocomplete: 'email',
      },
      validations: {
        required,
        email,
      },
    };
  }
}
