import { AbstractField } from '@/data/forms/fields/abstractField';
import { PHONE_NUMBER } from '@/services/validation/validations';
import { createPhoneNumberValidator } from '@/services/validation/createPhoneNumberValidator';
import { maxLength } from 'vuelidate/lib/validators';

export class PhoneField extends AbstractField {
  getField() {
    return {
      name: 'phone',
      type: 'tel',
      inputAttributes: {
        autocomplete: 'tel-national',
      },
      validations: {
        maxLength: maxLength(25),
        [PHONE_NUMBER]: createPhoneNumberValidator('cc'),
      },
    };
  }
}
