/**
 * Waits until form validation is not pending anymore.
 *
 * @returns {Promise<Boolean>}
 *
 * @see https://github.com/vuelidate/vuelidate/issues/179
 */
export function waitForValidation() {
  return new Promise((resolve) => {
    const unwatch = this.$watch(
      () => this.$v.$pending,
      (isPending) => {
        if (!isPending) {
          if (unwatch) {
            unwatch();
          }
          resolve(!this.$v.$invalid);
        }
      },
      { immediate: true },
    );
  });
}
